import axios from 'axios'
import qs from "qs"

const api = axios.create({
    timeout: 10000,
    baseURL: 'https://shikamaru.snapmp3.me/',
    paramsSerializer: (params) => qs.stringify(params, { encode: false })
})

export default api
