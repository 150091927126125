import React from 'react'

const ProgressBar = (props) => {
    const { bgColor, completed } = props
    const containerStyles = {
        height:20,
        width: '100%',
        backgroundColor: '#202020',
        borderRadius:2,
        margin:0
    }

    const fillerStyles = {
        height:'100%',
        width: `${completed}%`,
        backgroundColor: bgColor,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width 1s'
    }

    const labelStyles = {
        padding:5,
        color: 'white',
        fontWeight:'bold'
    }
    return(
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>{`${completed}%`}</span>
            </div>
        </div>
    )
}

export default ProgressBar